import { endOfDay, format, startOfDay } from "date-fns";
import { genericGet } from "services/genericService";
import { downloadEventsEndpoint, downloadSummarizedWorkTimeEndpoint, downloadWorkTimeEndpoint, getEventsEndpoint, getSummarizedWorkTimeEndpoint, getWorkTimeEndpoint } from "./endpoints";
import { ReportEventData, ReportEventsSortOrderBy, ReportPaginationData, ReportSummarizedWorkTimeData, ReportSummarizedWorkTimeSortOrderBy, ReportSummarizedWorkTimeTableData, ReportWorkTimeData, ReportWorkTimeSortOrderBy, ReportWorkTimeTableData } from "./types";

const getStart = (startDate: Date | null) => startDate ? format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
const getEnd = (endDate: Date | null) => endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
const getRange = (startDate: Date | null, endDate: Date | null) => ({ start: getStart(startDate), end: getEnd(endDate) });

type BasicReportProps<T> = {
    locationUuid: string;
    filter: string;
    employeeFilter?: string;
    sortOrder: 'asc' | 'desc';
    sortOrderBy: T;
    startDate: Date | null;
    endDate: Date | null;
    token?: string;
}

type GetReportProps<T> = BasicReportProps<T> & {
    page: number;
    pageSize: number;
}

type DownloadReportProps<T> = BasicReportProps<T> & {
    type?: 'csv' | 'xlsx';
    lang?: string;
}

const getAllEventReports = (props: GetReportProps<ReportEventsSortOrderBy>): Promise<ReportPaginationData<ReportEventData>> => {
    const {
        locationUuid,
        filter,
        sortOrder,
        sortOrderBy,
        page,
        pageSize,
        startDate,
        endDate,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<ReportPaginationData<ReportEventData>>(getEventsEndpoint(locationUuid, filter, sortOrder, sortOrderBy, page, pageSize, start, end), {}, token);
}

const downloadAllEventReports = (props: DownloadReportProps<ReportEventsSortOrderBy>): Promise<Blob> => {
    const {
        locationUuid,
        filter,
        sortOrder,
        sortOrderBy,
        startDate,
        endDate,
        type,
        lang,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<Blob>(
        downloadEventsEndpoint(locationUuid, filter, sortOrder, sortOrderBy, start, end, type, lang),
        { responseType: 'blob' },
        token
    )
}

const getAllWorkTimeReports = async (props: GetReportProps<ReportWorkTimeSortOrderBy>): Promise<ReportPaginationData<ReportWorkTimeTableData>> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        page,
        pageSize,
        startDate,
        endDate,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    const list = await genericGet<ReportPaginationData<ReportWorkTimeData>>(getWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, page, pageSize, employeeFilter, start, end), {}, token);
    return convertWorkTimeToTable(list);
}

const downloadAllWorkTimeReports = (props: DownloadReportProps<ReportWorkTimeSortOrderBy>): Promise<Blob> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        startDate,
        endDate,
        type,
        lang,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<Blob>(
        downloadWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, employeeFilter, start, end, type, lang),
        { responseType: 'blob' },
        token
    )
}

const getAllSummarizedWorkTimeReports = async (props: GetReportProps<ReportSummarizedWorkTimeSortOrderBy>): Promise<ReportPaginationData<ReportSummarizedWorkTimeTableData>> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        page,
        pageSize,
        startDate,
        endDate,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    const list = await genericGet<ReportPaginationData<ReportSummarizedWorkTimeData>>(getSummarizedWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, page, pageSize, employeeFilter, start, end), {}, token);

    return ({
        ...list,
        rows: list.rows.map(wt => ({
            employeeId: wt.employeeId,
            employeeDepartment: wt.employeeDepartment,
            employeeName: `${wt.employeeLastName} ${wt.employeeFirstName}`,
            workTime: wt.workTimeInSeconds ? getWorkTime(wt.workTimeInSeconds) : "",
            normalTime: getWorkTime(wt.normalTimeInSeconds),
            cameLateBy: wt.cameLateByInSeconds ? getWorkTime(wt.cameLateByInSeconds) : "",
            leftEarlierBy: wt.leftEarlierByInSeconds ? getWorkTime(wt.leftEarlierByInSeconds) : "",
            nightHours: wt.nightHoursInSeconds ? getWorkTime(wt.nightHoursInSeconds) : "",
            breakTime: wt.breakTimeInSeconds ? getWorkTime(wt.breakTimeInSeconds) : "",
        })),
    })
}

const downloadAllSummarizedWorkTimeReports = (props: DownloadReportProps<ReportSummarizedWorkTimeSortOrderBy>): Promise<Blob> => {
    const {
        locationUuid,
        filter,
        employeeFilter,
        sortOrder,
        sortOrderBy,
        startDate,
        endDate,
        type,
        lang,
        token
    } = props;

    const { start, end } = getRange(startDate, endDate)

    return genericGet<Blob>(
        downloadSummarizedWorkTimeEndpoint(locationUuid, filter, sortOrder, sortOrderBy, employeeFilter, start, end, type, lang),
        { responseType: 'blob' },
        token
    )
}

const convertWorkTimeToTable = (list: ReportPaginationData<ReportWorkTimeData>): ReportPaginationData<ReportWorkTimeTableData> => {
    return {
        ...list,
        rows: list.rows.map(wt => ({
            employeeId: wt.employeeId,
            day: wt.day,
            weekDay: wt.weekDay,
            employeeDepartment: wt.employeeDepartment,
            employeeName: `${wt.employeeLastName} ${wt.employeeFirstName}`,
            workTime: wt.workTimeInSeconds ? getWorkTime(wt.workTimeInSeconds) : "",
            normalTime: getWorkTime(wt.normalTimeInSeconds),
            cameLateBy: wt.cameLateByInSeconds ? getWorkTime(wt.cameLateByInSeconds) : "",
            leftEarlierBy: wt.leftEarlierByInSeconds ? getWorkTime(wt.leftEarlierByInSeconds) : "",
            nightHours: wt.nightHoursInSeconds ? getWorkTime(wt.nightHoursInSeconds) : "",
            breakTime: wt.breakTimeInSeconds ? getWorkTime(wt.breakTimeInSeconds) : "",
            entranceTime: wt.entranceTime?.split('.')[0].replace("T", " ") ?? "",
            exitTime: wt.exitTime ? wt.exitTime.split('.')[0].replace("T", " ") : "",
        })),
    };
}

const getWorkTime = (seconds: number = 0) => {
    if (seconds < 60) return `${seconds}s`;

    const minutes = Math.floor(seconds / 60);
    const hours = minutes >= 60 ? Math.floor(minutes / 60) : 0;

    return `${hours ? `${hours}h ` : ""}${minutes % 60}m`;
}

const convertWeekDayNumberToWeekDayString = (day: number): string => {
    if (day === 0) return "Sunday";
    if (day === 1) return "Monday";
    if (day === 2) return "Tuesday";
    if (day === 3) return "Wednesday";
    if (day === 4) return "Thursday";
    if (day === 5) return "Friday";
    if (day === 6) return "Saturday";

    return "";
}

export const reportsService = {
    getAllEventReports,
    downloadAllEventReports,
    getAllWorkTimeReports,
    downloadAllWorkTimeReports,
    getAllSummarizedWorkTimeReports,
    downloadAllSummarizedWorkTimeReports,
    convertWeekDayNumberToWeekDayString,
}
