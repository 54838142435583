export interface Report {
    deviceName: string;
    timestamp: string;
    eventTypeName: string;
    employeeId: string;
    employeeName: string;
    employeeFirstName: string;
    employeeLastName: string;
    entryStatus: string;
    employeeDepartment: string;
    day: Date;
    weekDay: number;
    scheduleStart: string;
    scheduleEnd: string;
    entranceTime?: string;
    exitTime: string;
    normalTime: string;
    workTime: string;
    workTimeInSeconds: number;
    normalTimeInSeconds: number;
    cameLateBy: string;
    cameLateByInSeconds: number;
    leftEarlierBy: string;
    leftEarlierByInSeconds: number;
    afterHours: string;
    nightHours: string;
    nightHoursInSeconds: number;
    breakTimeInSeconds: number;
    breakTime: string;
};

export interface ReportEventData {
    deviceName: Report['deviceName'];
    timestamp: Report['timestamp'];
    eventTypeName: Report['eventTypeName'];
    employeeId: Report['employeeId'];
    employeeName: Report['employeeName'];
    entryStatus: Report['entryStatus'];
}

export interface ReportWorkTimeData {
    employeeId: Report['employeeId'];
    employeeFirstName: Report['employeeFirstName'];
    employeeLastName: Report['employeeLastName'];
    employeeDepartment: Report['employeeDepartment'];
    day: Report['day'];
    weekDay: Report['weekDay'];
    entranceTime: Report['entranceTime'];
    exitTime: Report['exitTime'];
    normalTimeInSeconds?: Report['normalTimeInSeconds'];
    // scheduleStart?: Report['scheduleStart'];
    // scheduleEnd?: Report['scheduleEnd'];
    workTimeInSeconds?: Report['workTimeInSeconds'];
    cameLateByInSeconds?: Report['cameLateByInSeconds'];
    leftEarlierByInSeconds?: Report['leftEarlierByInSeconds'];
    // afterHours?: Report['afterHours'];
    nightHoursInSeconds?: Report['nightHoursInSeconds'];
    breakTimeInSeconds?: Report['breakTimeInSeconds'];
}

export interface ReportSummarizedWorkTimeData {
    employeeId: Report['employeeId'];
    employeeFirstName: Report['employeeFirstName'];
    employeeLastName: Report['employeeLastName'];
    employeeDepartment: Report['employeeDepartment'];
    normalTimeInSeconds?: Report['normalTimeInSeconds'];
    workTimeInSeconds?: Report['workTimeInSeconds'];
    cameLateByInSeconds?: Report['cameLateByInSeconds'];
    leftEarlierByInSeconds?: Report['leftEarlierByInSeconds'];
    nightHoursInSeconds?: Report['nightHoursInSeconds'];
    breakTimeInSeconds?: Report['breakTimeInSeconds'];
}

export interface ReportWorkTimeTableData {
    employeeId: Report['employeeId'];
    employeeName: Report['employeeName'];
    employeeDepartment: Report['employeeDepartment'];
    day: Report['day'];
    weekDay: Report['weekDay'];
    entranceTime: Report['entranceTime'];
    exitTime: Report['exitTime'];
    workTime: Report['workTime'];
    normalTime: Report['normalTime'];
    cameLateBy: Report['cameLateBy'];
    leftEarlierBy: Report['leftEarlierBy'];
    nightHours: Report['nightHours'];
    breakTime: Report['breakTime'];
}

export interface ReportSummarizedWorkTimeTableData {
    employeeId: Report['employeeId'];
    employeeName: Report['employeeName'];
    employeeDepartment: Report['employeeDepartment'];
    workTime: Report['workTime'];
    normalTime: Report['normalTime'];
    cameLateBy: Report['cameLateBy'];
    leftEarlierBy: Report['leftEarlierBy'];
    nightHours: Report['nightHours'];
    breakTime: Report['breakTime'];
}

export interface ReportPaginationData<T> {
    page: number;
    pageSize: number;
    count: number;
    sortOrder: 'asc' | 'desc';
    sortOrderBy: number;
    rows: T[];
}

export enum ReportEventsSortOrderBy {
    deviceName = 1,
    timestamp = 2,
    eventTypeName = 3,
    employeeId = 4,
    employeeName = 5,
    entryStatus = 6,
}

export enum ReportWorkTimeSortOrderBy {
    employeeId = 1,
    employeeName = 2,
    employeeDepartment = 3,
    day = 4,
    entranceTime = 5,
    exitTime = 6,
    normalTime = 7,
    weekDay = 9,
}

export enum ReportSummarizedWorkTimeSortOrderBy {

}
