import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { WorktimeScheduleGetShortNamesResponse } from "services/setting-management/types/worktimeSchedules";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontSize: 25,
    },
    paper: {
        paddingBottom: theme.spacing(2),
    },
    maxHeight: {
        [theme.breakpoints.down('lg')]: {
            maxHeight: 300,
            overflow: 'auto',
        },
    },
}));

interface WorkTimeScheduleListProps {
    handleListItemClick: (key: string) => void;
    selectedUuid: string;
    schedules: WorktimeScheduleGetShortNamesResponse[];
    handleClickDelete: (scheduleUuid: string) => void;
}

function WorkTimeScheduleList(props: WorkTimeScheduleListProps) {
    const { handleListItemClick, selectedUuid, schedules, handleClickDelete } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    
    return (
        <Paper className={classes.paper}>
            <Typography component="h3" className={classes.title}>
                {t("Schedules")}
            </Typography>
            <List component="nav" aria-label="schedules list" className={classes.maxHeight}>
                <ListItem
                    button
                    selected={!selectedUuid.length}
                    onClick={() => handleListItemClick("")}
                >
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("New type")} />
                </ListItem>
                {schedules.map(schedule => (
                    <ListItem
                        button
                        selected={selectedUuid === schedule.uuid}
                        onClick={() => handleListItemClick(schedule.uuid)}
                        key={schedule.uuid}
                    >
                        <ListItemText primary={schedule.name} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={() => handleClickDelete(schedule.uuid)}
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

export default WorkTimeScheduleList;
