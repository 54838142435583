import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { reportsService } from 'services/report-management/reports.service';
import { ReportPaginationData, ReportWorkTimeSortOrderBy, ReportWorkTimeTableData } from 'services/report-management/types';
import TablePagination from 'components/TablePagination';
import Holidays from 'date-holidays';
import { isSameDay, isSaturday, isSunday } from 'date-fns';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T, orderBySec: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    if (b[orderBySec] < a[orderBySec]) {
        return -1;
    }
    if (b[orderBySec] > a[orderBySec]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof ReportWorkTimeTableData>(
    order: Order,
    orderBy: Key,
    orderBySec: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, orderBySec)
        : (a, b) => -descendingComparator(a, b, orderBy, orderBySec);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof ReportWorkTimeTableData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'employeeId', numeric: true, label: 'Identifier' },
    { id: 'employeeName', numeric: false, label: 'Last and first name' },
    { id: 'employeeDepartment', numeric: false, label: 'Department' },
    { id: 'weekDay', numeric: false, label: 'Week day' },
    { id: 'day', numeric: false, label: 'Day' },
    // { id: 'scheduleStart', numeric: false, label: 'Schedule start' },
    // { id: 'scheduleEnd', numeric: false, label: 'Schedule end' },
    { id: 'entranceTime', numeric: false, label: 'Entrance time' },
    { id: 'exitTime', numeric: false, label: 'Exit time' },
    { id: 'normalTime', numeric: false, label: 'Stay' },
    { id: 'workTime', numeric: false, label: 'Time passed' },
    { id: 'cameLateBy', numeric: false, label: 'Came late by' },
    { id: 'leftEarlierBy', numeric: false, label: 'Left earlier by' },
    // { id: 'afterHours', numeric: false, label: 'After hours' },
    { id: 'nightHours', numeric: false, label: 'Night hours' },
    { id: 'breakTime', numeric: false, label: 'Break time' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ReportWorkTimeTableData) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof ReportWorkTimeTableData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const [t] = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ px: 1.5, textWrap: "nowrap" }}
                    >
                        {(headCell.id in ReportWorkTimeSortOrderBy) ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            t(headCell.label)
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

interface ReportsWorktimeTableProps {
    rows?: ReportPaginationData<ReportWorkTimeTableData>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number | "auto">>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<number>>;
    selectedAuto?: boolean;
    selectedEmployee?: string;
}

export default function ReportsWorktimeTable(props: ReportsWorktimeTableProps) {
    const { rows, setRowsPerPage, setPage, setOrder, setOrderBy, selectedAuto, selectedEmployee } = props;
    const classes = useStyles();
    const [t] = useTranslation();
    const holidays = new Holidays("PL");

    const orderByLabel = (property: keyof ReportWorkTimeTableData): ReportWorkTimeSortOrderBy => {
        if (property === 'day') return ReportWorkTimeSortOrderBy.day;
        if (property === 'weekDay') return ReportWorkTimeSortOrderBy.weekDay;
        if (property === 'employeeDepartment') return ReportWorkTimeSortOrderBy.employeeDepartment;
        if (property === 'employeeId') return ReportWorkTimeSortOrderBy.employeeId;
        if (property === 'employeeName') return ReportWorkTimeSortOrderBy.employeeName;
        if (property === 'exitTime') return ReportWorkTimeSortOrderBy.exitTime;
        if (property === 'normalTime') return ReportWorkTimeSortOrderBy.normalTime;
        return ReportWorkTimeSortOrderBy.entranceTime;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportWorkTimeTableData) => {
        const isAsc = rows?.sortOrder === 'asc';
        const newOrderLabel = orderByLabel(property);
        
        setOrder(newOrderLabel === rows?.sortOrderBy && !isAsc ? 'asc' : 'desc');
        setOrderBy(newOrderLabel);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (newRowsPerPage: number | "auto") => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const emptyRows = (rows && rows.count) ? rows.pageSize - Math.min(rows.pageSize, rows.count - rows.page * rows.pageSize) : 0;

    const checkIfIsWorkoff = (date: Date) => (
        isSunday(date)
        || isSaturday(date)
        || Boolean(holidays.getHolidays().filter(el => isSameDay(new Date(el.date), date)).length)
    )
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="workTimeReportsTable"
                        aria-label="work time reports table"
                        size='small'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={rows?.sortOrder === 'desc' ? 'asc' : 'desc'}
                            orderBy={ReportWorkTimeSortOrderBy[rows?.sortOrderBy ?? ReportWorkTimeSortOrderBy.entranceTime]}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {rows && (rows.rows as ReportWorkTimeTableData[])
                                .map((row, index) => {
                                    const labelId = `report-${index}`;
                                    const isHoliday = row.day ? checkIfIsWorkoff(new Date(row.day)) : false;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{
                                                opacity: isHoliday ? 0.4 : 1,
                                                "th, td": {
                                                    px: 1.5,
                                                },
                                            }}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.employeeId}
                                            </TableCell>
                                            <TableCell >{row.employeeName}</TableCell>
                                            <TableCell >{row.employeeDepartment}</TableCell>
                                            <TableCell>{t(reportsService.convertWeekDayNumberToWeekDayString(row.weekDay))}</TableCell>
                                            <TableCell sx={{ minWidth: 110 }}>{row.day}</TableCell>
                                            {/* <TableCell>{row.scheduleStart}</TableCell> */}
                                            {/* <TableCell>{row.scheduleEnd}</TableCell> */}
                                            <TableCell>{row.entranceTime}</TableCell>
                                            <TableCell>{row.exitTime}</TableCell>
                                            <TableCell>{row.normalTime}</TableCell>
                                            <TableCell>{row.workTime}</TableCell>
                                            <TableCell>{row.cameLateBy}</TableCell>
                                            <TableCell>{row.leftEarlierBy}</TableCell>
                                            {/* <TableCell>{row.afterHours}</TableCell> */}
                                            <TableCell>{row.nightHours}</TableCell>
                                            <TableCell>{row.breakTime}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={12} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {rows && !!rows.count && <TablePagination
                    rowsPerPageOptions={[5, 10, 35]}
                    autoRowEnabled={selectedEmployee === '0'}
                    page={rows.page}
                    rowsPerPage={selectedAuto ? "auto" : rows.pageSize}
                    count={rows.count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
        </div>
    );
}
