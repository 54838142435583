import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import LocationSelect from 'components/LocationSelect';
import { useSessionContext } from "contexts/SessionContext";
import { useSnackbar, VariantType } from "notistack";
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { WorktimeScheduleCreateRequest, WorktimeShiftScheduleCreateRequest } from 'services/setting-management/types/worktimeSchedules';
import { worktimeSchedulesService } from 'services/setting-management/worktimeSchedules.service';
import DeleteIcon from '@mui/icons-material/Delete';
import { EmployeeGetAllResponse, employeesService } from 'services/company-management';
import { useCheckboxInput, useInput, useMultiselectInput, useNumberInput } from 'hooks/input-hook';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface ShiftRowProps {
    shift: WorktimeShiftScheduleCreateRequest;
    handleEditRow: (newRow: WorktimeShiftScheduleCreateRequest, index: number) => void;
    shiftScheduleChecked: boolean;
    shiftNumber: number;
    disabledDelete: boolean;
    removeShift: (index: number) => void;
}

function ShiftRow(props: ShiftRowProps) {
    const { shift, handleEditRow, shiftScheduleChecked, shiftNumber, disabledDelete, removeShift } = props;
    const { countFrom, start, stop, countUntil, recognizeFrom, recognizeUntil } = shift;

    const handleEditField = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length) {
            handleEditRow({ ...shift, [event.target.name]: event.target.value as string }, shiftNumber);
        }
    };

    return (
        <TableRow>
            {shiftScheduleChecked &&
                <TableCell component="th" scope="row" align="center">
                    { shiftNumber + 1 }
                </TableCell>
            }
            <TableCell component="th" scope="row" align="center">
                <TextField
                    id="time"
                    type="time"
                    name="countFrom"
                    value={countFrom}
                    required
                    onChange={handleEditField}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="time"
                    type="time"
                    name="start"
                    value={start}
                    required
                    onChange={handleEditField}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="time"
                    type="time"
                    name="stop"
                    value={stop}
                    required
                    onChange={handleEditField}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    id="time"
                    type="time"
                    name="countUntil"
                    value={countUntil}
                    required
                    onChange={handleEditField}
                />
            </TableCell>
            {shiftScheduleChecked &&
                <TableCell align="center">
                    <TextField
                        id="time"
                        type="time"
                        name="recognizeFrom"
                        value={recognizeFrom}
                        required={shiftScheduleChecked}
                        onChange={handleEditField}
                    />
                </TableCell>}
            {shiftScheduleChecked &&
                <TableCell align="center">
                    <TextField
                        id="time"
                        type="time"
                        name="recognizeUntil"
                        value={recognizeUntil}
                        required={shiftScheduleChecked}
                        onChange={handleEditField}
                    />
                </TableCell>}
            {shiftScheduleChecked &&
                <TableCell align="center">
                    <IconButton
                        aria-label="delete"
                        onClick={() => removeShift(shiftNumber)}
                        disabled={disabledDelete}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </TableCell>}
        </TableRow>
    );
}

interface AddWorkTimeScheduleListProps {
    selectedUuid: string;
    handleClose?: () => void;
}

function AddWorkTimeSchedule(props: AddWorkTimeScheduleListProps) {
    const { selectedUuid, handleClose } = props;
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: shiftScheduleChecked, setValue: setShiftScheduleChecked, bind: bindShiftScheduleChecked } = useCheckboxInput(false);
    const [ shifts, setShifts ] = useState<WorktimeShiftScheduleCreateRequest[]>([]);
    const { value: dayStart, setValue: setDayStart, bind: bindDayStart } = useInput("00:00", /^\d{2}:\d{2}$/);
    const { value: nightHoursFrom, setValue: setNightHoursFrom, bind: bindNightHoursFrom } = useInput("22:00", /^\d{2}:\d{2}$/);
    const { value: nightHoursTo, setValue: setNightHoursTo, bind: bindNightHoursTo } = useInput("06:00", /^\d{2}:\d{2}$/);
    const { value: breakTime, setValue: setBreakTime, bind: bindBreakTime } = useNumberInput(15, 1, 120);
    const { value: roundTime, setValue: setRoundTime, bind: bindRoundTime } = useNumberInput(5, 1, 30);
    const { value: roundDownChecked, setValue: setRoundDownChecked, bind: bindRoundDownChecked } = useCheckboxInput(false);
    // const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput('');
    const { value: departmentsSelectedUuid, bind: bindDepartmentsSelectedUuid, setValue: setDepartmentsSelectedUuid } = useMultiselectInput([]);
    const { value: selectedEmployeesUuid, bind: bindSelectedEmployeesUuid, setValue: setSelectedEmployeesUuid } = useMultiselectInput([]);
    const [ departmentsError, setDepartmentsError ] = useState<boolean>(false);

    const [ employees, setEmployees ] = useState<EmployeeGetAllResponse[]>([]);

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        loadData()
    }, [selectedUuid]);

    const loadData = () => {
        employeesService.getAll(sessionContext.token)
            .then(res => res.sort((a, b) => a.lastName > b.lastName ? 1 : -1))
            .then(res => setEmployees(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
        
        if (!selectedUuid.length) return resetData();
        
        worktimeSchedulesService.getWorktimeSchedule(selectedUuid, sessionContext.token)
            .then(res => {
                setName(res.name);
                setShiftScheduleChecked(res.isShiftSchedule);
                setDayStart(res.dayStart);
                setNightHoursFrom(res.nightStart);
                setNightHoursTo(res.nightEnd);
                setBreakTime(res.breakTimeInMinutes);
                setRoundTime(res.roundBreakTimeInMinutes);
                setRoundDownChecked(res.isRoundToDown);
                setShifts(res.shifts);
                setSelectedEmployeesUuid(res.employees.map(e => e.uuid));
                setDepartmentsSelectedUuid(res.departments.map(d => d.uuid));
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const resetData = () => {
        setName("");
        setShiftScheduleChecked(false);
        setDayStart("00:00");
        setNightHoursFrom("22:00");
        setNightHoursTo("06:00");
        setBreakTime(15);
        setRoundTime(5);
        setRoundDownChecked(false);
        // setLocation("");
        setSelectedEmployeesUuid([]);
        setDepartmentsSelectedUuid([]);

        setShifts([{
            shiftNumber: 1,
            countFrom: "08:00",
            start: "08:00",
            stop: "16:00",
            countUntil: "16:00",
            recognizeFrom: "08:00",
            recognizeUntil: "16:00",
        }])
    }

    const handleOnSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        if (!ifValidateOk()) return;

        const dept: WorktimeScheduleCreateRequest = {
            name,
            isShiftSchedule: shiftScheduleChecked,
            shifts,
            dayStart,
            nightStart: nightHoursFrom,
            nightEnd: nightHoursTo,
            breakTimeInMinutes: breakTime,
            roundBreakTimeInMinutes: roundTime,
            isRoundToDown: roundDownChecked,
            employees: selectedEmployeesUuid.map((uuid: string) => ({ uuid })),
            departments: departmentsSelectedUuid.map((uuid: string) => ({ uuid })),
        }

        try {
            if (selectedUuid.length) await worktimeSchedulesService.updateWorktimeSchedule(selectedUuid, dept, sessionContext.token);
            else await worktimeSchedulesService.createWorktimeSchedule(dept, sessionContext.token);

            if (handleClose) handleClose()
            resetData();
            handleSnackBar(selectedUuid.length ? ResponseSuccessMessages.EDIT : ResponseSuccessMessages.ADD, "success");
        } catch (err) {
            handleSnackBar(getCreateErrorText(err as number), 'error')
        }
    }

    const timeStringToMinutes = (time?: string) => time?.toString().split(":").map((n, i) => parseInt(n) * (i ? 1 : 60)).reduce((a, b) => a + b, 0)

    const ifValidateOk = (): boolean => {
        for (const shift of shifts) {
            const countFromInMinutes = timeStringToMinutes(shift.countFrom)
            const startInMinutes = timeStringToMinutes(shift.start)
            const stopInMinutes = timeStringToMinutes(shift.stop)
            const countUntilInMinutes = timeStringToMinutes(shift.countUntil)
            
            if (countFromInMinutes && startInMinutes && countFromInMinutes > startInMinutes) {
                handleSnackBar("'Start' must be after 'Count from'", "error")
                return false;
            }

            if (stopInMinutes && countUntilInMinutes && countFromInMinutes && stopInMinutes > countUntilInMinutes && countUntilInMinutes > countFromInMinutes) {
                handleSnackBar("'Count until' must be after 'Stop' and before 'Count from'", "error")
                return false;
            }
        }

        return true;
    }

    const handleEditRow = (newRow: WorktimeShiftScheduleCreateRequest, index: number) => {
        const temp = shifts.slice();

        temp[index] = newRow;

        setShifts(temp);
    }

    const addNewShift = () => {
        const temp = shifts.slice();

        temp.push({
            shiftNumber: temp.length + 1,
            countFrom: "08:00",
            start: "08:00",
            stop: `16:00`,
            countUntil: "16:00",
            recognizeFrom: "08:00",
            recognizeUntil: "16:00",
        })

        setShifts(temp);
    }

    const removeShift = (index: number) => {
        const temp = shifts.slice();

        temp.splice(index, 1);

        setShifts(temp);
    }

    const leaveFirstShift = () => {
        const temp = shifts.slice();

        temp.splice(1);

        setShifts(temp)
    }
    
    return (
        <Paper sx={{ pb: 2 }} component='form' onSubmit={handleOnSubmit}>
            <Typography component="h3" sx={{
                backgroundColor: "primary.main",
                color: "white",
                p: 1,
                mx: "auto",
                textAlign: "center",
                fontSize: 25,
            }}>
                {t("Schedule settings")}
            </Typography>
            <Grid container direction="column" justifyContent="space-around" alignItems="center">
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        id="scheduleName"
                        label={t("Schedule name")}
                        name="scheduleName"
                        autoComplete="scheduleName"
                        autoFocus
                        {...bindName}
                    />
                </Grid>

                <Grid item xs={12} container sx={{ p: 2 }}>
                    <Grid item sx={{ width: "100%" }}>
                        <Paper sx={{ p: 2, pb: 0 }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("Choose schedule type")}</FormLabel>
                                <Grid container item justifyContent="space-around">
                                    <Box display="block">
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>{t('Standard schedule (1 shift)')}</Grid>
                                            <Grid item>
                                                <Switch color='primary' name="shiftScheduleChecked" {...bindShiftScheduleChecked} onClick={leaveFirstShift} />
                                            </Grid>
                                            <Grid item>{t('Shift schedule')}</Grid>
                                        </Grid>
                                    </ Box>
                                </Grid>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} container sx={{ p: 2 }} direction="column">
                    {shiftScheduleChecked && (
                        <Grid item sx={{ mb: 2 }}>
                            <Button variant="contained" color="primary" onClick={addNewShift}>
                                {t("Add schedule")}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Paper>
                            <FormControl sx={{ width: "100%" }}>
                                <Typography variant="subtitle1" id="tableTitle" flexGrow={1} flexShrink={0} flexBasis="100%" sx={{ p: 1, pl: 2 }}>
                                    {t("Schedule declaration")}
                                </Typography>
                                <TableContainer sx={{ maxHeight: 350 }}>
                                    <Table stickyHeader aria-label="schedule settings table" >
                                        <TableHead>
                                            <TableRow sx={{ width: "100%" }}>
                                                {shiftScheduleChecked && <TableCell sx={{ width: 80 }}>{t("Shift")}</TableCell>}
                                                <TableCell align="center">{t("Count from")}</TableCell>
                                                <TableCell align="center">{t("Start")}</TableCell>
                                                <TableCell align="center" >{t("Stop")}</TableCell>
                                                <TableCell align="center">{t("Count until")}</TableCell>
                                                {shiftScheduleChecked && <TableCell align="center">{t("Recognize from")}</TableCell>}
                                                {shiftScheduleChecked && <TableCell align="center">{t("Recognize until")}</TableCell>}
                                                {shiftScheduleChecked && <TableCell align="center">{t("Actions")}</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {shifts.map((shift, index) => (
                                                <ShiftRow
                                                    key={index}
                                                    shiftScheduleChecked={shiftScheduleChecked}
                                                    shift={shift}
                                                    handleEditRow={handleEditRow}
                                                    shiftNumber={index}
                                                    disabledDelete={shifts.length === 1}
                                                    removeShift={removeShift}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item container xs={12} spacing={2} sx={{ p: 2 }}>
                    <Grid container item spacing={2}>
                        <Grid item sx={{ mt: 1 }}>
                            <Typography>{t("Day start")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="time"
                                type="time"
                                name="dayStart"
                                {...bindDayStart}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item sx={{ mt: 1 }}>
                            <Typography>{t("Night hours from")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="time"
                                type="time"
                                name="nightHoursFrom"
                                {...bindNightHoursFrom}
                            />
                        </Grid>
                        <Grid item sx={{ mt: 1 }}>
                            <Typography>{t("to")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="time"
                                type="time"
                                name="nightHoursTo"
                                {...bindNightHoursTo}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item sx={{ pt: 4 }}>
                            <Typography>{t("Break time length")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="breakTime"
                                variant='standard'
                                InputProps={{ inputProps: { min: "1", max: "999" } }}
                                {...bindBreakTime}
                            />
                        </Grid>
                        <Grid item sx={{ pt: 4 }}>
                            <Typography>{t("minutes")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item sx={{ pt: 4 }}>
                            <Typography>{t("Round times to")}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                name="roundTime"
                                variant='standard'
                                InputProps={{ inputProps: { min: "1", max: "99" } }}
                                {...bindRoundTime}
                            />
                        </Grid>
                        <Grid item sx={{ pt: 4 }}>
                            <Typography>{t("minutes")}</Typography>
                        </Grid>
                        <Grid item sx={{ pt: 4 }}>
                            <Box display="block">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>{t('Up')}</Grid>
                                    <Grid item>
                                        <Switch color='primary' name="roundDownChecked" {...bindRoundDownChecked} />
                                    </Grid>
                                    <Grid item>{t('Down')}</Grid>
                                </Grid>
                            </ Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2} sx={{ p: 2 }}>
                    <Grid item xs>
                        <Paper sx={{ p: 2 }}>
                            <FormControl fullWidth>
                                <FormLabel>{t("Assign schedule")}</FormLabel>
                                <Grid container spacing={4} justifyContent="flex-start">
                                    <Grid item>
                                        <Box mt={2}>
                                            <LocationSelect
                                                type="department"
                                                multiple
                                                noOverrideDefaultValues
                                                error={departmentsError}
                                                setValue={setDepartmentsSelectedUuid}
                                                input={<Input />}
                                                {...bindDepartmentsSelectedUuid}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box mt={2}>
                                            <FormControl>
                                                <InputLabel id="employee-select-label">{t("Choose employees")}</InputLabel>
                                                <Select
                                                    labelId="employee-select-label"
                                                    id="employee-select"
                                                    multiple
                                                    input={<Input />}
                                                    MenuProps={MenuProps}
                                                    sx={{ minWidth: 200, maxWidth: 300 }}
                                                    {...bindSelectedEmployeesUuid}
                                                >
                                                    {employees.map((employee, index) => (
                                                        <MenuItem
                                                            key={employee.uuid} value={employee.uuid}
                                                            sx={{
                                                                fontWeight: selectedEmployeesUuid.indexOf(employee.uuid) === -1 ? 400 : 500
                                                            }}
                                                        >
                                                            {employee.lastName} {employee.firstName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>

                <Box sx={{ width: "100%", p: 1 }}>
                    <Grid container spacing={2} direction="row-reverse">
                        <Grid item lg={2} md={3} xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t("Save")}
                            </Button>
                        </Grid>
                        <Grid item lg={2} md={3} xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={loadData}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Paper>
    );
}

export default AddWorkTimeSchedule;
