import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
  isHover: boolean;
}

const Day: React.FunctionComponent<DayProps> = ({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  outlined,
  filled,
  onClick,
  onHover,
  value,
  isHover,
}: DayProps) => {

  const getBorder = (position: "top" | "bottom") => {
    return <Box sx={{
      position: "absolute",
      [position]: 0,
      right: startOfRange ? 0 : undefined,
      left: endOfRange ? 0 : undefined,
      width: isHover || startOfRange || endOfRange ? "50%" : "100%",
      height: 2,
      [position === 'top' ? "borderTopWidth" : "borderBottomWidth"]: 1,
      [position === 'top' ? "borderTopColor" : "borderBottomColor"]: "primary.light",
      [position === 'top' ? "borderTopStyle" : "borderBottomStyle"]: "dashed",
      display: (!disabled && highlighted) ? "block" : "none",
    }}></Box>
  }
  
  return (
    <Box
      sx={{
        display: 'flex',
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          height: 32,
          width: 32,
          padding: 0,
          border: (theme) => !disabled && outlined ? `1px solid ${theme.palette.primary.dark}` : undefined,
          ...(!disabled && filled ? {
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
            backgroundColor: (theme) => theme.palette.primary.dark,
          } : {}),
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
        // size="large"
      >
        <Typography
          sx={{
            lineHeight: 1.6,
            color: (theme) => !disabled
              ? (filled ? theme.palette.primary.contrastText : theme.palette.text.primary)
              : theme.palette.text.secondary,
          }}
          variant="body2"
        >
          {value}
        </Typography>
      </IconButton>

      {getBorder("top")}
      {getBorder("bottom")}
    </Box>
  );
};

export default Day;
