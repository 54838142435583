import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { getMonth, getYear } from 'date-fns';
import LazyLoadIcon from 'components/LazyLoadIcon';

interface HeaderProps {
  date: Date;
  // eslint-disable-next-line no-unused-vars
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  locale?: Locale;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  locale
}: HeaderProps) => {
  const MONTHS = typeof locale !== 'undefined'
    ? [0,1,2,3,4,5,6,7,8,9,10,11].map(d => locale.localize?.month(d, {width: 'abbreviated', context: 'standalone'}))
    : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <LazyLoadIcon iconName='ChevronLeft' />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant='subtitle1'>{MONTHS[getMonth(date)]}</Typography>
      </Grid>

      <Grid item>
        <Typography variant='subtitle1'>{getYear(date)}</Typography>
      </Grid>
      <Grid item sx={{ padding: '5px' }}>
        <IconButton
          sx={{
            padding: '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <LazyLoadIcon iconName='ChevronRight' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;