import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSessionContext } from 'contexts/SessionContext';
import WorkTimeScheduleList from './WorkTimeScheduleList';
import AddWorkTimeSchedule from './AddWorkTimeSchedule';
import { WorktimeScheduleGetShortNamesResponse } from 'services/setting-management/types/worktimeSchedules';
import { useSnackbar, VariantType } from 'notistack';
import { worktimeSchedulesService } from 'services/setting-management/worktimeSchedules.service';
import { getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import DeleteDialog from 'components/DeleteDialog';
import DialogOverlay from 'components/DialogOverlay';

export default function WorktimeSettings() {
    const [sessionContext] = useSessionContext();
    const [t] = useTranslation();

    const [selected, setSelected] = useState<string>("");
    const [ schedules, setSchedules ] = useState<WorktimeScheduleGetShortNamesResponse[]>([]);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [editedRow, setEditedRow] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        worktimeSchedulesService.getAll(sessionContext.token)
            .then(res => setSchedules(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const handleClickDelete = (scheduleUuid: string) => {
        setEditedRow(scheduleUuid);
        setDeleteOpen(true);
    }

    const handleDelete = () => {
        worktimeSchedulesService.deleteWorktimeSchedule(editedRow, sessionContext.token)
            .then(() => {
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
                handleClose();
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    }

    const handleListItemClick = (key: string) => {
        setSelected(key);
    };

    const handleClose = () => {
        loadData();
        setSelected("");
        setDeleteOpen(false);
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <WorkTimeScheduleList
                    handleListItemClick={handleListItemClick}
                    selectedUuid={selected}
                    schedules={schedules}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
            <Grid item md xs>
                <AddWorkTimeSchedule
                    selectedUuid={selected}
                    handleClose={handleClose}
                />
            </Grid>
        </Grid>
        

        <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete work time schedule")}>
            <DeleteDialog type="work time schedule" handleDelete={handleDelete} names={schedules.filter(schedule => schedule.uuid === editedRow).map(schedule => schedule.name)} handleClose={handleClose} />
        </DialogOverlay>
    </>;
}